// libraries
import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions';

// js
import { LOGOUT, UPDATE_USER } from './constants';

const initialState = {
    user: null,
};

const handlers = {
    [LOGOUT]: () => initialState,
    [UPDATE_USER]: (state = initialState, action: AnyAction) => {
        const user = action.payload;
        return { ...state, user };
    },
};

export default handleActions(handlers, initialState);
