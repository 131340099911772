/* eslint-disable max-len */
// libraries
import React from 'react';
import {
    Switch, Route, useHistory, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

// pages
import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import ForgotPassword from '../pages/ForgotPassword/ForgotPasswordPage';
import PreloadPage from '../pages/Preload/PreloadPage';
import HomePage from '../pages/Home/HomePage';
import AcquisitionPage from '../pages/Acquisition/AcquisitionPage';

// js
import { RootState } from '../store';

const Routes: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const history = useHistory();

    // conditions
    if (!window.location.pathname.includes('/forgotPassword') && !window.location.pathname.includes('/register') && !window.location.pathname.includes('/login') && !infoUser) {
        history.push('/');
    }

    // rota para redirecionar o usuário quando digitar uma rota não encontrada.
    const pathNotFound = infoUser ? '/home' : '/';
    const RouteNotFound = infoUser ? HomePage : PreloadPage;

    return (
        <Switch>
            <Route path="/" exact component={PreloadPage} />
            <Route path="/login" exact component={LoginPage} />
            <Route path={['/forgotPassword/:email', '/forgotPassword']} component={ForgotPassword} />
            <Route path={['/register/:social', '/register']} exact component={RegisterPage} />
            <Route path="/home" exact component={HomePage} />
            <Route path="/acquisition" exact component={AcquisitionPage} />
            <Route path={pathNotFound} component={RouteNotFound} />
            <Redirect to={pathNotFound} />
        </Switch>
    );
};

export default Routes;
