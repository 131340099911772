/* eslint-disable @typescript-eslint/no-explicit-any */
// js
import constants from '../utils/consts';

// constants
const { baseUrl } = constants;

// api
async function GetContents(
    idUser: string,
    token: string,
): Promise<{ formatContents: any, actionHighLight: any}> {
    const configsGet = {
        method: 'GET',
        headers: {
            'x-access-token': token,
        },
    };
    const dataCategories: any = await fetch(`${baseUrl}/category`, configsGet);
    const dataCategoriesJson = await dataCategories.json();
    const resultCategories = dataCategoriesJson?.result || [];
    const formatContents: any = [];
    const categoriesToPromiseAll = resultCategories.map(async (category: any) => {
        const response: any = await fetch(`${baseUrl}/contents?idUser=${idUser}&idCategory=${category.id}`, configsGet);
        const responseJson = await response.json();
        const dataContents = responseJson?.result || [];
        formatContents.push({
            category,
            contents: dataContents,
        });
        return responseJson;
    });
    await Promise.all(categoriesToPromiseAll);

    // get highlight
    const responseHighlight: any = await fetch(`${baseUrl}/contents`, configsGet);
    const responseHighlightJson = await responseHighlight.json();
    const dataHighlight = responseHighlightJson?.result || [];
    const [newHighlight] = dataHighlight.filter((el: any) => el.isHighlight);
    let actionHighLight: any = [];
    if (newHighlight) {
        const categoriesHighlight: any = await fetch(`${baseUrl}/category`, configsGet);
        const categoriesHighlightJson = await categoriesHighlight.json();
        const resultCategoriesHighlight = categoriesHighlightJson?.result || [];
        const category = resultCategoriesHighlight.find(
            (el: any) => el.id === newHighlight.idCategory,
        );
        const formatNewHighlight = {
            category,
            content: newHighlight,
        };
        actionHighLight = formatNewHighlight;
    }
    const AllInfo: { formatContents: any, actionHighLight: any } = {
        formatContents,
        actionHighLight,
    };
    return AllInfo;
}

export default {
    GetContents,
};
