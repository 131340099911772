// libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// js
import auth from '../../services/auth';
import {
    Home,
    Logo,
    ContainerGoBack,
    TextHeader,
} from './forgotPasswordStyles';
import { arrowLeftPink, logoFullSize } from '../../assets/index';

// components
import InputAuth from '../../components/Auth/Input/InputComponent';
import ButtonAuth from '../../components/Auth/Button/ButtonComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface getParamsForgotPasswordInterfaces {
    email?: string,
}

const ForgotPasswordPage: React.FC = () => {
    // constants
    const getParams: getParamsForgotPasswordInterfaces = useParams();

    // states
    const [emailRecover, setEmailRecover] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmailRecover = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmailRecover(valueFormat);
    };

    const onChangeOldPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setOldPassword(valueFormat);
    };

    const onChangeNewPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setNewPassword(valueFormat);
    };

    const onChangeConfirmNewPassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setConfirmNewPassword(valueFormat);
    };

    const TradePassword = async () => {
        if (getParams?.email) {
            // eslint-disable-next-line no-alert
            alert('Request de trocar a senha!');
        } else {
            const ActionRecoverPassword = await auth.RecoverPassword(emailRecover);
            setEmailRecover('');
            // eslint-disable-next-line no-alert
            alert(ActionRecoverPassword.message);
        }
    };

    const ValidationColor = () => {
        if (getParams?.email) {
            return emailRecover.length > 0
            && oldPassword.length > 0
            && newPassword.length > 0
            && confirmNewPassword.length > 0;
        }

        return emailRecover.length > 0;
    };

    const GoBack = () => {
        setPathRoute('/login');
        setRedirect(true);
    };

    // useEffect
    useEffect(() => {
        setEmailRecover(getParams?.email || '');
    }, []);

    return (
        <Home>
            <ToNavigation pathRoute={pathRoute} redirect={redirect} />
            <ContainerGoBack src={arrowLeftPink} onClick={GoBack} />
            <Logo src={logoFullSize} />
            <TextHeader>Favor inserir seu e-mail</TextHeader>
            {!getParams?.email ? (
                <InputAuth placeholder="Email" value={emailRecover} type="email" onChange={onChangeEmailRecover} />
            ) : null}
            {getParams?.email ? (
                <>
                    <InputAuth placeholder="Senha antiga" value={oldPassword} type="password" onChange={onChangeOldPassword} />
                    <InputAuth placeholder="Nova senha" value={newPassword} type="password" onChange={onChangeNewPassword} />
                    <InputAuth placeholder="Confirmar nova senha" value={confirmNewPassword} type="password" onChange={onChangeConfirmNewPassword} />
                </>
            ) : null}
            <ButtonAuth onClick={TradePassword} activeButton={ValidationColor()} label={getParams?.email ? 'Alterar senha' : 'Enviar'} />
        </Home>
    );
};

export default ForgotPasswordPage;
