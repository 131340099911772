// libraries
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

// js
import {
    Header,
    Home,
    ContainerDrawerOption,
    DrawerOption,
    IconDrawerOption,
    OpenIcon,
    HamburgerOpen,
    ContainerMenu,
} from './menuStyles';
import {
    iconDrawerWelcome,
    iconDrawerCategories,
    iconDrawerFormats,
    iconDrawerSearch,
    minimizeDrawerRight,
    iconDrawerIntenseCategories,
    iconDrawerIntenseWelcome,
    iconDrawerIntenseAuthors,
    iconDrawerIntenseFormats,
    iconDrawerIntenseSearch,
    iconDrawerAuthors,
    minimizeDrawerLeft,
} from '../../assets/index';
// import { logoutAuth } from '../../store/modules/user/actions';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface Props {
    setVisibleDrawer: (visibleDrawer: boolean) => void,
}

const MenuComponent: React.FC<Props> = (props: Props) => {
    // dispatch
    // const dispatch = useDispatch();

    // consts
    const location = useLocation();
    const { setVisibleDrawer } = props;
    const allTabs = [
        {
            id: 1,
            title: 'Olá',
            path: '/home',
            icon: iconDrawerWelcome,
            iconFocus: iconDrawerIntenseWelcome,
        },
        {
            id: 2,
            title: 'Categorias',
            path: '/categories',
            icon: iconDrawerCategories,
            iconFocus: iconDrawerIntenseCategories,
        },
        {
            id: 3,
            title: 'Autores',
            path: '/authors',
            icon: iconDrawerAuthors,
            iconFocus: iconDrawerIntenseAuthors,
        },
        {
            id: 4,
            title: 'Formatos',
            path: '/formats',
            icon: iconDrawerFormats,
            iconFocus: iconDrawerIntenseFormats,
        },
        {
            id: 5,
            title: 'Buscar',
            path: '/search',
            icon: iconDrawerSearch,
            iconFocus: iconDrawerIntenseSearch,
        },
    ];

    // states
    const [currentLocation, setCurrentLocation] = useState('/');
    const [visibleItem, setVisibleItem] = useState(false);
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // useEffect
    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location]);

    // methods
    // const onLogout = () => {
    //     dispatch(logoutAuth());
    // };

    const tradeVisibleItem = () => {
        const newVisibleItem = !visibleItem;
        setVisibleDrawer(newVisibleItem);
        setVisibleItem(newVisibleItem);
    };

    // renders
    const RenderSubtitles = () => (
        allTabs.map((v) => {
            const GoToNavigationTab = () => {
                setPathRoute(v.path);
                setRedirect(true);
            };
            return (
                <ContainerDrawerOption
                    visibleItem={visibleItem}
                    onClick={GoToNavigationTab}
                    key={v.id}
                    disabled={!visibleItem}
                >
                    <IconDrawerOption src={v.path === currentLocation ? v.icon : v.iconFocus} />
                    {visibleItem ? (
                        <DrawerOption colorAction={v.path === currentLocation}>
                            {v.title}
                        </DrawerOption>
                    ) : null}
                </ContainerDrawerOption>
            );
        })
    );

    return (
        <ContainerMenu>
            <Header visibleItem={visibleItem}>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <HamburgerOpen onClick={tradeVisibleItem} visibleItem={visibleItem}>
                    <OpenIcon src={visibleItem ? minimizeDrawerLeft : minimizeDrawerRight} />
                </HamburgerOpen>
                <Home visibleItem={visibleItem}>
                    {RenderSubtitles()}
                </Home>
            </Header>
        </ContainerMenu>
    );
};

export default MenuComponent;
