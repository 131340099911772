// libraries
import styled from 'styled-components';

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
`;

export const Container = styled.div`
    background: linear-gradient(100deg, rgba(255, 221, 234, 1), rgba(255, 255, 255, 1));
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const Logo = styled.img`
    height: 236px;
    width: 236px;

    /* media query */
    @media (max-width: 1024px) {
        height: 150px;
        width: 150px;
    }
`;

export const ContainerGoBack = styled.img`
    height: auto;
    width: auto;
    cursor: pointer;
    max-width: 30px;
    max-height: 30px;
    position: absolute;
    left: 20px;
    top: 20px;
`;
