// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface ButtonOptionInterfaces {
    backgroundColor?: string,
    border?: string,
}

interface TextActionNavigationInterfaces {
    color?: string,
    fontSize?: string,
}

// styles
export const Logo = styled.img`
    height: 236px;
    width: 236px;

    /* media query */
    @media (max-width: 1024px) {
        height: 150px;
        width: 150px;
    }
`;

export const ContainerGoBack = styled.img`
    height: auto;
    width: auto;
    cursor: pointer;
    max-width: 30px;
    max-height: 30px;
    position: absolute;
    left: 20px;
    top: 20px;
`;

export const Container = styled.div`
    background: linear-gradient(100deg, rgba(255, 221, 234, 1), rgba(255, 255, 255, 1));
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const ContainerOrLine = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30px;
    margin-bottom: 15px;
    width: 100%;
`;

export const Line = styled.div`
    background-color: ${colors.theme.pinkWhite};
    height: 1px;
    min-width: 10%;
    /* media query */
        @media (max-width: 512px) {
        min-width: 30%;
    }
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
`;

export const ButtonOption = styled.div<ButtonOptionInterfaces>`
    cursor: pointer;
    align-items: center;
    display: flex;
    background-color: ${(props) => (props.backgroundColor || 'transparent')};
    border: ${(props) => (props.border || 'none')};
    border-radius: 30px;
    margin-bottom: 20px;
    padding-left: 10px;
    min-height: 50px;
    transition-duration: 400ms;
    width: 30%;
    /* media query */
        @media (max-width: 512px) {
        height: 40px;
        width: 80%;
    }
`;

export const IconOption = styled.img`
    height: auto;
    width: auto;
    max-width: 30px;
    max-height: 30px;
`;

export const TextOption = styled.text<TextActionNavigationInterfaces>`
    color: ${(props) => (props.color || colors.theme.grayIntense)};
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
    @media (max-width: 512px) {
        font-size: 16px;
    }
`;

export const TextActionNavigation = styled.h4`
    color: ${colors.theme.pinkHot};
    font-weight: normal;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const TextLinkingNavigation = styled.a`
    color: ${colors.theme.pinkHot};
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
`;
