const isLocal = window.location.hostname.includes('localhost');
const isDevelopment = window.location.hostname.includes('development');
const isStaging = window.location.hostname.includes('staging');

const getUrlWEB = (): string => {
    if (isDevelopment) {
        return 'https://development.easylivingwebclient.dotcoding.com.br';
    }
    if (isStaging) {
        return 'https://staging.easylivingwebclient.dotcoding.com.br';
    }
    return 'https://dazzling-hodgkin-6060cf.netlify.app';
};

const getUrlAPI = (): string => {
    if (isDevelopment || isLocal) {
        return 'https://development.easylivingapi.dotcoding.com.br';
    }
    if (isStaging) {
        return 'https://staging.easylivingapi.dotcoding.com.br';
    }
    return 'https://staging.easylivingapi.com.br';
};

export default {
    getUrlWEB,
    getUrlAPI,
};
