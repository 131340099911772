/* eslint-disable @typescript-eslint/no-explicit-any */
// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// js
import {
    Banner,
    ButtonPlay,
    Container,
    Home,
    ContainerInner,
    ContainerHome,
    TitleBanner,
    ContentHome,
    LogoBanner,
    ContainerDefault,
    ImgProfile,
    TextDefault,
    CursorPointer,
    ImgPlay,
    ContainerProfile,
    TextPlay,
    ContainerCards,
    TitleCards,
    Card,
    DescriptionCard,
    ContainerAdjustCards,
    BlurredDescription,
    ContainerShadowBanner,
    IconCard,
} from './homeStyles';
import {
    logoBanner,
    iconUser,
    iconPlay,
    iconTypeMovie,
    iconTypeSound,
    iconTypeText,
    iconFavorite,
} from '../../assets/index';
import homeApi from '../../services/home';
import { RootState } from '../../store';

// components
import MenuComponent from '../../components/Menu/MenuComponent';

const HomePage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const history = useHistory();

    // states
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allInfoScreen, setAllInfoScreen] = useState<
    { formatContents: any, actionHighLight: any}>();

    // methods
    const GetInfoHome = async () => {
        const GetAllContents = await homeApi.GetContents(
            infoUser?.id,
            infoUser?.token,
        );
        setAllInfoScreen(GetAllContents);
    };

    const GoToPurchase = async () => {
        history.push({
            pathname: '/acquisition',
            state: { dataBanner: allInfoScreen?.actionHighLight },
        });
    };

    // useEffect
    useEffect(() => {
        GetInfoHome();
    }, []);

    // renders
    const RenderAllContents = () => (
        allInfoScreen?.formatContents.map((v: any) => {
            if (v.contents.length) {
                return (
                    <ContainerCards key={v.category.id}>
                        <TitleCards>{`— ${v.category.name}`}</TitleCards>
                        <ContainerAdjustCards>
                            {v.contents.map((value: any) => {
                                const RenderIconType = () => {
                                    if (value.type === 'video') {
                                        return iconTypeMovie;
                                    }
                                    if (value.type === 'text') {
                                        return iconTypeText;
                                    }
                                    if (value.type === 'audio') {
                                        return iconTypeSound;
                                    }

                                    return '';
                                };

                                return (
                                    <Card key={value.id} imgCard={value.cover}>
                                        <IconCard src={RenderIconType()} />
                                        <BlurredDescription>
                                            <DescriptionCard>{value.description}</DescriptionCard>
                                        </BlurredDescription>
                                        <IconCard src={iconFavorite} />
                                    </Card>
                                );
                            })}
                        </ContainerAdjustCards>
                    </ContainerCards>
                );
            }

            return null;
        })
    );
    const RenderScreen = () => (
        <ContainerHome>
            <Banner
                imgBanner={allInfoScreen?.actionHighLight?.content?.cover}
                visibleDrawer={visibleDrawer}
            >
                <ContainerShadowBanner visibleDrawer={visibleDrawer}>
                    <ContainerDefault alignSelf="flex-end" flexDirection="column" height="80px" visibleDrawer={visibleDrawer}>
                        <TitleBanner>{allInfoScreen?.actionHighLight?.content?.title || ''}</TitleBanner>
                        <ButtonPlay onClick={GoToPurchase} disabled={!allInfoScreen}>
                            <TextPlay>OUÇA AGORA</TextPlay>
                            <ImgPlay src={iconPlay} />
                        </ButtonPlay>
                    </ContainerDefault>
                    <ContainerProfile>
                        <LogoBanner src={logoBanner} />
                        <CursorPointer>
                            <ImgProfile src={iconUser} />
                            <TextDefault fontWeight="bold">Perfil</TextDefault>
                        </CursorPointer>
                    </ContainerProfile>
                </ContainerShadowBanner>
            </Banner>
            <ContentHome visibleDrawer={visibleDrawer}>
                {RenderAllContents()}
            </ContentHome>
        </ContainerHome>
    );

    return (
        <Container>
            <Home>
                <ContainerInner>
                    <MenuComponent setVisibleDrawer={setVisibleDrawer} />
                    {RenderScreen()}
                </ContainerInner>
            </Home>
        </Container>
    );
};

export default HomePage;
