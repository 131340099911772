// libraries
import React, { useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InstagramLogin from 'instagram-login-react';
import { useDispatch } from 'react-redux';

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import urls from '../../utils/urls';
import {
    Home,
    Logo,
    TextActionNavigation,
    TextLinkingNavigation,
    ButtonOption,
    IconOption,
    TextOption,
    Container,
    ContainerBody,
} from './preloadStyles';
import {
    logoFullSize,
    iconFacebook,
    iconGoogle,
    iconEmail,
    iconInstagram,
} from '../../assets/index';
import { updateUser } from '../../store/modules/user/actions';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface GoogleRenderProps {
    onClick: () => void,
    disabled?: boolean | undefined,
}

const PreloadPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // constants
    const stylesButtonFacebook: React.CSSProperties = {
        backgroundColor: 'transparent',
        color: colors.theme.blue,
        borderRadius: 30,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minWidth: '30%',
        fontWeight: 'bold',
        marginBottom: 20,
    };

    // useState
    const [activeComponents, setActiveComponents] = useState(false);
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const GoToRegister = async () => {
        setPathRoute('/register');
        setRedirect(true);
    };

    const SigninSocial = async (result: { id: string, username?: string, email?: string}) => {
        const RegisterSocial = await auth.LoginSocial(
            result.username || '',
            result.email || '',
            result.id,
        );
        if (RegisterSocial.status === 200) {
            const formatInfoUser = {
                ...RegisterSocial.result.social,
                token: RegisterSocial.result.token,
                tokenRefresh: RegisterSocial.result.tokenRefresh,
            };
            dispatch(updateUser(formatInfoUser));
            setPathRoute('/home');
            setRedirect(true);
        } else {
            // eslint-disable-next-line no-alert
            alert(RegisterSocial.message || 'Ocorreu um erro ao criar sua conta com login social.');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseGoogle = (response: any) => {
        if (response.profileObj) {
            const result = {
                email: response.profileObj.email || '',
                name: response.profileObj.name || '',
                id: response.profileObj.googleId,
            };
            SigninSocial(result);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseFacebook = async (response: any) => {
        if (response) {
            const result = {
                email: response.email || '',
                name: response.name || '',
                id: response.id,
            };
            SigninSocial(result);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseInstagram = async (response: any) => {
        const GetInfoSocial = await auth.GetTokenSocial(
            '438798047161039',
            'c128c7621e4a25f9c8ecfa4baf5ad0cf',
            'authorization_code',
            urls.getUrlWEB(),
            response,
        );
        if (GetInfoSocial.status === 200) {
            SigninSocial(GetInfoSocial.result);
        } else {
            // eslint-disable-next-line no-alert
            alert(GetInfoSocial.message || 'Ocorreu um erro ao autenticar com o Login Social');
        }
    };

    // renders
    const RenderButtonGoogle = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.basic.orange}`} onClick={renderProps.onClick}>
            <IconOption src={iconGoogle} />
            <TextOption color={colors.basic.orange}>Criar com Google</TextOption>
        </ButtonOption>
    );

    const RenderButtonFacebook = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.theme.blue}`} onClick={renderProps.onClick}>
            <IconOption src={iconFacebook} />
            <TextOption color={colors.theme.blue}>Criar com Facebook</TextOption>
        </ButtonOption>
    );

    const RenderButtonInstagram = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.basic.purple}`} onClick={renderProps.onClick}>
            <IconOption src={iconInstagram} />
            <TextOption color={colors.basic.purple}>Criar com Instagram</TextOption>
        </ButtonOption>
    );

    // useEffect
    useEffect(() => {
        setTimeout(() => {
            setActiveComponents(true);
        }, 1000);
    }, []);

    // main
    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <Logo src={logoFullSize} />
                <ContainerBody display={activeComponents}>
                    <TextActionNavigation fontSize="20px">Crie sua conta</TextActionNavigation>
                    <ButtonOption backgroundColor={colors.basic.white} onClick={GoToRegister}>
                        <IconOption src={iconEmail} />
                        <TextOption>Cadastre-se com seu e-mail</TextOption>
                    </ButtonOption>
                    <GoogleLogin
                        clientId="318677929949-ogp84v6rhqldmmnm461269apago3q3gl.apps.googleusercontent.com"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy="single_host_origin"
                        render={RenderButtonGoogle}
                    />
                    <FacebookLogin
                        appId="499935777656640"
                        fields="name,email,picture"
                        callback={responseFacebook}
                        buttonStyle={stylesButtonFacebook}
                        render={RenderButtonFacebook}
                    />
                    <InstagramLogin
                        clientId="438798047161039"
                        onSuccess={responseInstagram}
                        onFailure={responseInstagram}
                        scope="user_profile,user_media"
                        render={RenderButtonInstagram}
                    />
                    <TextActionNavigation color={colors.theme.pinkLightMedium}>
                        Já tem conta?
                        <TextLinkingNavigation href="/login" type="button" color={colors.theme.pinkLightMedium}>Fazer login</TextLinkingNavigation>
                    </TextActionNavigation>
                    <TextActionNavigation fontSize="12px">
                        Ao usar o Easy Living, você concorda com nossos
                        <TextLinkingNavigation fontSize="13px">Termos e Condições</TextLinkingNavigation>
                    </TextActionNavigation>
                </ContainerBody>
            </Home>
        </Container>
    );
};

export default PreloadPage;
