// js
import constants from '../utils/consts';

// constants
const { baseUrl } = constants;

// interfaces
interface ResponderRequestInterfaces {
    status: number,
    result: any,
    message: string,
}

// api
async function GetAuthor(
    searchName: string,
    token: string,
): Promise<ResponderRequestInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/authors?${searchName && `searchName=${searchName}`}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: null,
            status: 500,
        };
    }
}

export default {
    GetAuthor,
};
