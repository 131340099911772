// libraries
import React from 'react';

// js
import { InputAuthStyles } from './inputStyles';

// interfaces
interface Props {
    placeholder: string,
    value: string,
    type: string,
    onChange: (txt: React.ChangeEvent<HTMLInputElement>) => void,
}

const InputAuthComponent: React.FC<Props> = ({
    placeholder,
    value,
    type,
    onChange,
}: Props) => (
    <InputAuthStyles
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
    />
);

export default InputAuthComponent;
