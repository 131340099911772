// libraries
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// js
import auth from '../../services/auth';
import {
    Home,
    Logo,
    Container,
    ContainerGoBack,
} from './registerStyles';
import {
    logoFullSize,
    arrowLeftPink,
} from '../../assets/index';
import { updateUser } from '../../store/modules/user/actions';

// components
import InputAuth from '../../components/Auth/Input/InputComponent';
import ButtonAuth from '../../components/Auth/Button/ButtonComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';

const RegisterPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeName = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setName(valueFormat);
    };

    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setPassword(valueFormat);
    };

    const Signup = async () => {
        const RegisterUser = await auth.Register(
            name,
            email,
            password,
        );
        if (RegisterUser.status === 201) {
            const formatInfoUser = {
                ...RegisterUser.result.user,
                token: RegisterUser.result.token,
                tokenRefresh: RegisterUser.result.tokenRefresh,
            };
            dispatch(updateUser(formatInfoUser));
            setPathRoute('/home');
            setRedirect(true);
        } else {
            // eslint-disable-next-line no-alert
            alert(RegisterUser.message);
        }
    };

    const GoBack = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerGoBack src={arrowLeftPink} onClick={GoBack} />
                <Logo src={logoFullSize} />
                <InputAuth placeholder="Nome" value={name} type="email" onChange={onChangeName} />
                <InputAuth placeholder="Email" value={email} type="email" onChange={onChangeEmail} />
                <InputAuth placeholder="Senha" value={password} type="password" onChange={onChangePassword} />
                <ButtonAuth onClick={Signup} activeButton={name.length > 0 && email.length > 0 && password.length > 0} label="Cadastrar" />
            </Home>
        </Container>
    );
};

export default RegisterPage;
