// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface MenuInterface {
    currentLocation?: boolean,
    visibleItem?: boolean,
}

interface DrawerOptionInterfaces {
    colorAction?: boolean,
}

// styles - menu
export const Header = styled.div<MenuInterface>`
    align-items: center;
    background-color: ${colors.theme.backgroundDrawer};
    justify-items: center;
    display: flex;
    position: fixed;
    flex-direction: column;
    transition-duration: 400ms;
    padding-bottom: 50px;
    backdrop-filter: blur(5px);
    z-index: 1;
    position: absolute;
    width: ${(props) => (props.visibleItem ? '200px' : '50px')};

    /* media query */
    @media (max-width: 800px) {
        width: ${(props) => (props.visibleItem ? '100px' : '40px')};
        height: ${(props) => (props.visibleItem ? 'auto' : '40px')};
        padding-bottom: 20px;
    }
`;

export const ContainerMenu = styled.div`
    background-color: ${colors.basic.black};
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    display: flex;
`;

export const Home = styled.div<MenuInterface>`
    padding: 20px;
    background-color: transparent;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 200px;
    @media (max-width: 800px) {
        width: 100px;
        display: ${(props) => (props.visibleItem ? 'flex' : 'none')};
    }
`;

export const HamburgerOpen = styled.div<MenuInterface>`
    display: none;
    z-index: 4;
    cursor: pointer;
    align-self: ${(props) => (props.visibleItem ? 'flex-start' : 'center')};
    margin-left: ${(props) => (props.visibleItem ? '10px' : '0px')};
    transition-duration: 400ms;
    display: flex;
    padding: 5px;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    @media (max-width: 800px) {
        margin-top: ${(props) => (props.visibleItem ? '15px' : '5px')};
        margin-left: -5px;
    }
`;

export const OpenIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
    @media (max-width: 800px) {
        width: 8px;
        width: 8px;
    }
`;

export const ContainerDrawerOption = styled.button<MenuInterface>`
    align-items: center;
    border: none;
    display: flex;
    cursor: ${(props) => (props.visibleItem ? 'pointer' : 'default')};
    margin-bottom: 20px;
    background-color: transparent;
    margin-left: ${(props) => (props.visibleItem ? '0px' : '75px')};
    width: ${(props) => (props.visibleItem ? '200px' : '20px')};

    /* media query */
    @media (max-width: 800px) {
        display: ${(props) => (props.visibleItem ? 'flex' : 'none')};
        width: ${(props) => (props.visibleItem ? '100px' : '20px')};
        margin-left: -10px;
    }
`;

export const IconDrawerOption = styled.img`
    object-fit: contain;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    z-index: 10;
    overflow: hidden;
    @media (max-width: 1200px) {
        height: 15px;
        width: 15px;
    }
    @media (max-width: 800px) {
        height: 10px;
        width: 10px;
    }
`;

export const DrawerOption = styled.text<DrawerOptionInterfaces>`
    color: ${(props) => (props.colorAction ? colors.basic.white : colors.theme.grayUltraIntense)};
    font-size: 16px;
    font-weight: bold;
    z-index: 10;
    overflow: hidden;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
    @media (max-width: 800px) {
        font-size: 12px;
    }
`;
