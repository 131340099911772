// libraries
import {
    fadeIn,
    slideInLeft,
    slideInUp,
} from 'react-animations';
import styled, { keyframes } from 'styled-components';

// js
import colors from '../../styles/colors';

// constants
const fadeInAnimation = keyframes`${fadeIn}`;
const slideInUpAnimation = keyframes`${slideInUp}`;
const slideInLeftAnimation = keyframes`${slideInLeft}`;

// interfaces
interface TextDefaultInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}

interface ContainerDefaultInterfaces {
    alignSelf?: string,
    flexDirection?: string,
    height?: string,
    visibleDrawer?: boolean,
    paddingLeft?: string,
}

interface BannerInterfaces {
    imgBanner?: string,
}

interface CursorPointerInterfaces {
    marginTop?: string,
    marginBottom?: string,
    newCursor?: string,
}

interface DescriptionAuthorInterfaces {
    lineClamp?: boolean,
}

interface ContainerInfoDescriptionInterfaces {
    flexDirection?: string,
    alignItems?: string,
}

interface ProgressActualInterfaces {
    progressActual?: string,
}

interface ContainerInterfaces {
    openScrollBar?: boolean,
}

// styles
export const Home = styled.div`
    background-color: ${colors.basic.black};
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
`;

export const ContainerPayment = styled.button`
    align-self: center;
    border: 1px solid ${colors.theme.pinkHot};
    background-color: transparent;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-top: 40px;
    padding: 10px;
`;

export const ContainerInfoMedias = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ContainerInfoDescription = styled.div<ContainerInfoDescriptionInterfaces>`
    align-items: ${(props) => (props.alignItems || 'flex-start')};
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
`;

export const ContainerBlurred = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    backdrop-filter: blur(30px) contrast(70%);
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    background-color: transparent;
    border-radius: 20px;
    margin-bottom: 20px;
    /* transition-duration: 800ms; */
    height: 90px;
    width: 100%;
    animation: 1s ${slideInUpAnimation};
`;

export const IconPlay = styled.img`
    object-fit: contain;
    max-height: 35px;
    max-width: 35px;
    height: auto;
    width: auto;
`;

export const IconFavorite = styled.img`
    object-fit: contain;
    max-height: 25px;
    max-width: 20px;
    margin-right: 10px;
    cursor: pointer;
    height: auto;
    width: auto;
`;

export const AllProgressBar = styled.div`
    background-color: ${colors.basic.black};
    border-radius: 10px;
    height: 5px;
    width: 100%;
`;

export const ProgressBarActual = styled.div<ProgressActualInterfaces>`
    background-color: ${colors.theme.progressMovie};
    border-radius: 10px;
    height: 5px;
    width: ${(props) => (props.progressActual || '0px')};
`;

export const DescriptionAuthor = styled.text<DescriptionAuthorInterfaces>`
    font-size: 12px;
    color: ${colors.basic.white};
    -webkit-line-clamp: ${(props) => (props.lineClamp ? 'initial' : 2)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
`;

export const Container = styled.div<ContainerInterfaces>`
    background-color: ${colors.basic.black};
    display: flex;
    flex: 1;
    flex-direction: column;
    transition-duration: 400ms;
    overflow: ${(props) => (props.openScrollBar ? 'auto' : 'hidden')};
`;

export const ViewMore = styled.div`
    text-align: end;
    color: ${colors.basic.white};
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
`;

export const ContainerProfile = styled.div<ContainerDefaultInterfaces>`
    align-self: ${(props) => (props.alignSelf || 'flex-start')};
    align-items: center;
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
`;

export const LogoBanner = styled.img`
    object-fit: contain;
    height: 45px;
    width: 190px;
    margin-right: 10px;
    @media (max-width: 1200px) {
        height: 35px;
        width: 100px;
    }
    @media (max-width: 800px) {
        height: 25px;
        width: 60px;
    }
`;

export const CursorPointer = styled.button<CursorPointerInterfaces>`
    align-items: center;
    border: none;
    background-color: transparent;
    display: flex;
    cursor: ${(props) => (props.newCursor || 'pointer')};
    margin-top: ${(props) => (props.marginTop || '0px')};
    margin-bottom: ${(props) => (props.marginBottom || '0px')};
`;

export const ImgProfile = styled.img`
    height: 32px;
    margin-right: 10px;
    width: 32px;
    @media (max-width: 1200px) {
        height: 24px;
        width: 24px;
    }
    @media (max-width: 800px) {
        height: 16px;
        width: 16px;
    }
`;

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.white)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    position: relative;
    z-index: 2;
    @media (max-width: 1200px) {
        font-size: 10px;
    }
    @media (max-width: 800px) {
        font-size: 8px;
    }
`;

export const TitleBanner = styled.text`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 30px;
    @media (max-width: 1200px) {
        font-size: 25px;
    }
    @media (max-width: 800px) {
        font-size: 20px;
    }
`;

export const ImgAuthor = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    animation: 1s ${slideInLeftAnimation};
    width: 35%;
`;

export const ContainerListEpisodes = styled.div`
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    width: 64%;
`;

export const ContainerInfo = styled.div`
    align-self: center;
    margin-top: 100px;
    justify-content: space-between;
    /* background-color: wheat; */
    display: flex;
    min-width: 95%;
`;

export const Banner = styled.div<BannerInterfaces>`
    max-height: 90vh;
    min-width: 100%;
    transition-duration: 400ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props) => (`url(${props.imgBanner})`)};
`;

export const ContainerShadowBanner = styled.div<BannerInterfaces>`
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
    display: flex;
    animation: 1s ${fadeInAnimation};
    min-height: 100%;
    width: 100%;
    transition-duration: 400ms;
`;
