// js
import reducer from './reducers';
import { logoutAuth, updateUser } from './actions';

// constants
const actions = {
    logoutAuth,
    updateUser,
};

export default { actions, reducer };
