// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// interfaces
interface ButtonActionInterfaces {
    valueLength: boolean,
}

// styles
export const ButtonAuthStyles = styled.button<ButtonActionInterfaces>`
    cursor: ${(props) => (props.valueLength ? 'pointer' : 'auto')};
    background-color: ${(props) => (props.valueLength ? colors.theme.pink : colors.basic.white)};
    border-radius: 30px;
    border: none;
    margin-top: 20px;
    height: 50px;
    transition-duration: 400ms;
    width: 30%;
    /* media query */
    @media (max-width: 512px) {
        height: 40px;
        width: 80%;
    }
`;

export const TextButtonAuthStyles = styled.h4<ButtonActionInterfaces>`
    color: ${(props) => (props.valueLength ? colors.basic.white : colors.theme.pink)};
    font-weight: bold;
    font-size: 18px;
`;
