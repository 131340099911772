const colors = {
    basic: {
        black: '#000000',
        gray: '#707070',
        orange: '#F85B51',
        purple: '#9751DC',
        white: '#ffffff',
    },
    theme: {
        blue: '#3879FC',
        grayIntense: '#9B9B9B',
        backgroundDrawer: 'rgba(80,80,80, 0.5)',
        grayUltraIntense: '#919191',
        pink: '#E27898',
        pinkWhite: '#FFD8E4',
        pinkHot: '#FFAACD',
        pinkLightMedium: '#F298AB',
        purple: '#A460DE',
        pinkMoreUltra: '#DE607A',
        progressMovie: '#FF5353',
    },
};

export default colors;
