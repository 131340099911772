// js
import constants from '../utils/consts';

// constants
const { baseUrl } = constants;

// interfaces
interface ResponderRequestInterfaces {
    status: number,
    result: any,
    message: string,
}

// api
async function GetMedias(
    idContent: string,
    token: string,
): Promise<ResponderRequestInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
            headers: {
                'x-access-token': token,
            },
        };
        const response = await fetch(`${baseUrl}/medias?idContent=${idContent}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: null,
            status: 500,
        };
    }
}

export default {
    GetMedias,
};
