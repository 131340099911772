// libraries
import styled from 'styled-components';

// js
import colors from '../../../styles/colors';

// styles
export const InputAuthStyles = styled.input`
    color: ${colors.theme.pink};
    ::placeholder {
        color: ${colors.theme.pink};
    };
    border: ${colors.theme.pinkWhite} 1px solid;
    background-color: transparent;
    border-radius: 30px;
    font-size: 16px;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 15px;
    width: 40%;
    margin-top: 10px;
    /* media query */
    @media (max-width: 512px) {
        height: 40px;
        width: 90%;
    }
`;
