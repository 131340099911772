const moneyMask = (value: string): string => {
    const valueFormat = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(valueFormat) / 100,
    );

    return `R$ ${result}`;
};

const toPrice = (price: number, scale?: number): string => (
    String(((price * 100) / 100).toFixed(scale)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
);

export default {
    toPrice,
    moneyMask,
};
