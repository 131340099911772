// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// styles
export const Logo = styled.img`
    height: 236px;
    width: 236px;

    /* media query */
    @media (max-width: 1024px) {
        height: 150px;
        width: 150px;
    }
`;

export const ContainerGoBack = styled.img`
    height: auto;
    width: auto;
    cursor: pointer;
    max-width: 30px;
    max-height: 30px;
    position: absolute;
    left: 20px;
    top: 20px;
`;

export const TextHeader = styled.h4`
    color: ${colors.theme.pink};
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const Home = styled.div`
    align-items: center;
    display: flex;
    background: linear-gradient(100deg, rgba(255, 221, 234, 1), rgba(255, 255, 255, 1));
    flex: 1;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
`;
