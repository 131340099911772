/* eslint-disable max-len */
// libraries
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// js
import {
    Home,
    Container,
    ContainerInner,
    ContainerProfile,
    LogoBanner,
    CursorPointer,
    ImgProfile,
    TextDefault,
    Banner,
    ContainerShadowBanner,
    TitleBanner,
    ContainerInfo,
    ContainerContent,
    ContainerListEpisodes,
    ImgAuthor,
    ContainerPayment,
    DescriptionAuthor,
    ViewMore,
    ContainerBlurred,
    ContainerInfoMedias,
    ContainerInfoDescription,
    IconPlay,
    IconFavorite,
    AllProgressBar,
    ProgressBarActual,
} from './acquisitionStyles';
import {
    logoBanner,
    iconUser,
    iconFavorite,
    iconFavoriteDisabled,
    play,
} from '../../assets/index';
import authorsApi from '../../services/authors';
import mediasApi from '../../services/medias';
import colors from '../../styles/colors';
import functions from '../../utils/functions';
import { RootState } from '../../store';
import { DataAuthorsInterface } from '../../interfaces';

const AcquisitionPage: React.FC = () => {
    // useSelector
    const infoUser = useSelector((state: RootState) => state.user.user);

    // constants
    const location = useLocation();
    const actionHighLight = JSON.stringify(location.state);
    const parseActionHighLight = JSON.parse(actionHighLight)?.dataBanner;

    // states
    const [author, setAuthor] = useState<DataAuthorsInterface>();
    const [medias, setMedias] = useState<Array<any>>([]);
    const [actionLineClamp, setActionLineClamp] = useState(true);
    const [openScrollBar, setOpenScrollBar] = useState(false);

    // methods
    const GetInfoScreen = async () => {
        const GetOneAuthors = await authorsApi.GetAuthor(
            parseActionHighLight?.content?.author,
            infoUser.token,
        );
        if (GetOneAuthors.status === 200) {
            setAuthor(GetOneAuthors?.result[0]);
            if (GetOneAuthors?.result[0].description.length > 241) {
                setActionLineClamp(false);
            }
        }
        const GetAllMedias = await mediasApi.GetMedias(
            parseActionHighLight?.content?.id,
            infoUser.token,
        );
        if (GetAllMedias.status === 200) {
            const AddFavorite = GetAllMedias.result.map((v: any) => {
                const newObject = {
                    ...v,
                    favorite: false,
                };
                return newObject;
            });
            setMedias(AddFavorite);
        }
    };

    const TradeLineClamp = () => {
        setActionLineClamp(!actionLineClamp);
    };

    // useEffect
    useEffect(() => {
        GetInfoScreen();
        setTimeout(() => {
            setOpenScrollBar(true);
        }, 2000);
    }, []);

    // renders
    const RenderListEpisodes = () => {
        if (medias.length) {
            return (
                medias.map((v, i) => {
                    const actionFavorite = () => {
                        const modificatedMediaActual = medias.map((value) => {
                            const newObject = {
                                ...value,
                                favorite: !value.favorite,
                            };
                            return newObject;
                        });
                        setMedias(modificatedMediaActual);
                    };
                    return (
                        <ContainerBlurred>
                            <ContainerInfoMedias>
                                <ContainerInfoDescription flexDirection="column">
                                    <TextDefault fontWeight="bold" fontSize="12px">{`Episódio ${i + 1}`}</TextDefault>
                                    <TextDefault fontWeight="bold">{v.title}</TextDefault>
                                    <TextDefault fontWeight="normal" fontSize="12px">{v.description}</TextDefault>
                                </ContainerInfoDescription>
                                <ContainerInfoDescription alignItems="center">
                                    <IconFavorite
                                        src={v.favorite ? iconFavorite : iconFavoriteDisabled}
                                        onClick={actionFavorite}
                                    />
                                    <IconPlay src={play} />
                                </ContainerInfoDescription>
                            </ContainerInfoMedias>
                            <AllProgressBar>
                                <ProgressBarActual progressActual="80%" />
                            </AllProgressBar>
                        </ContainerBlurred>
                    );
                })
            );
        }

        return null;
    };

    return (
        <Container openScrollBar={openScrollBar}>
            <Home>
                <Banner imgBanner={parseActionHighLight?.content?.cover}>
                    <ContainerShadowBanner>
                        <ContainerInner>
                            <ContainerProfile alignSelf="flex-end">
                                <LogoBanner src={logoBanner} />
                                <CursorPointer>
                                    <ImgProfile src={iconUser} />
                                    <TextDefault fontWeight="bold">Perfil</TextDefault>
                                </CursorPointer>
                            </ContainerProfile>
                            <ContainerInfo>
                                <ContainerContent>
                                    <TitleBanner>
                                        {parseActionHighLight?.content?.title}
                                    </TitleBanner>
                                    <CursorPointer marginTop="40px" marginBottom="20px" newCursor="default">
                                        <ImgAuthor src={author?.backgroundCard} />
                                        <TextDefault fontWeight="bold" fontSize="16px">{author?.name}</TextDefault>
                                    </CursorPointer>
                                    <DescriptionAuthor lineClamp={actionLineClamp}>
                                        {author?.description}
                                    </DescriptionAuthor>
                                    {author?.description && author?.description.length > 241 ? (
                                        <ViewMore onClick={TradeLineClamp}>
                                            {actionLineClamp ? 'Ver menos' : 'Ver mais'}
                                        </ViewMore>
                                    ) : null}
                                    <ContainerPayment>
                                        <TextDefault fontWeight="normal" fontSize="15px" color={colors.theme.pinkHot}>
                                            {`R$ ${functions.toPrice(parseActionHighLight?.content?.price, 2)} | `}
                                            <TextDefault fontWeight="bold" fontSize="15px" color={colors.theme.pinkHot}>Comprar Conteúdo</TextDefault>
                                        </TextDefault>
                                    </ContainerPayment>
                                </ContainerContent>
                                <ContainerListEpisodes>
                                    {RenderListEpisodes()}
                                </ContainerListEpisodes>
                            </ContainerInfo>
                        </ContainerInner>
                    </ContainerShadowBanner>
                </Banner>
            </Home>
        </Container>
    );
};

export default AcquisitionPage;
