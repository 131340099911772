// libraries
import React, { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InstagramLogin from 'instagram-login-react';
import { useDispatch } from 'react-redux';

// js
import auth from '../../services/auth';
import colors from '../../styles/colors';
import urls from '../../utils/urls';
import {
    Home,
    Logo,
    TextActionNavigation,
    TextLinkingNavigation,
    ButtonOption,
    IconOption,
    Container,
    TextOption,
    ContainerOrLine,
    Line,
    ContainerGoBack,
} from './loginStyles';
import {
    logoFullSize,
    arrowLeftPink,
    iconGoogle,
    iconFacebook,
    iconInstagram,
} from '../../assets/index';
import { updateUser } from '../../store/modules/user/actions';

// components
import InputAuth from '../../components/Auth/Input/InputComponent';
import ButtonAuth from '../../components/Auth/Button/ButtonComponent';

// components (navigation)
import ToNavigation from '../../routes/navigation';

// interfaces
interface GoogleRenderProps {
    onClick: () => void,
    disabled?: boolean | undefined,
}

const LoginPage: React.FC = () => {
    // dispatch
    const dispatch = useDispatch();

    // constants
    const stylesButtonFacebook: React.CSSProperties = {
        backgroundColor: 'transparent',
        color: colors.theme.blue,
        borderRadius: 30,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        minWidth: '30%',
        fontWeight: 'bold',
        marginBottom: 20,
    };

    // states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pathRoute, setPathRoute] = useState('');
    const [redirect, setRedirect] = useState(false);

    // methods
    const onChangeEmail = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setEmail(valueFormat);
    };

    const onChangePassword = (txt: React.ChangeEvent<HTMLInputElement>) => {
        const valueFormat = txt.target.value;
        setPassword(valueFormat);
    };

    const GoBack = () => {
        setPathRoute('/');
        setRedirect(true);
    };

    const Signin = async () => {
        const LoginUser = await auth.Login(
            email,
            password,
        );
        if (LoginUser.status === 200) {
            const formatInfoUser = {
                ...LoginUser.result.user,
                token: LoginUser.result.token,
                tokenRefresh: LoginUser.result.tokenRefresh,
            };
            dispatch(updateUser(formatInfoUser));
            setPathRoute('/home');
            setRedirect(true);
        } else {
            // eslint-disable-next-line no-alert
            alert(LoginUser.message);
        }
    };

    const SigninSocial = async (result: { id: string, username?: string, email?: string}) => {
        const RegisterSocial = await auth.LoginSocial(
            result.username || '',
            result.email || '',
            result.id,
        );
        if (RegisterSocial.status === 200) {
            const formatInfoUser = {
                ...RegisterSocial.result.social,
                token: RegisterSocial.result.token,
                tokenRefresh: RegisterSocial.result.tokenRefresh,
            };
            dispatch(updateUser(formatInfoUser));
            setPathRoute('/home');
            setRedirect(true);
        } else {
            // eslint-disable-next-line no-alert
            alert(RegisterSocial.message || 'Ocorreu um erro ao criar sua conta com login social.');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseGoogle = (response: any) => {
        if (response.profileObj) {
            const result = {
                email: response.profileObj.email || '',
                name: response.profileObj.name || '',
                id: response.profileObj.googleId,
            };
            SigninSocial(result);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseFacebook = async (response: any) => {
        if (response) {
            const result = {
                email: response.email || '',
                name: response.name || '',
                id: response.id,
            };
            SigninSocial(result);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseInstagram = async (response: any) => {
        const GetInfoSocial = await auth.GetTokenSocial(
            '438798047161039',
            'c128c7621e4a25f9c8ecfa4baf5ad0cf',
            'authorization_code',
            urls.getUrlWEB(),
            response,
        );
        if (GetInfoSocial.status === 200) {
            SigninSocial(GetInfoSocial.result);
        } else {
            // eslint-disable-next-line no-alert
            alert(GetInfoSocial.message || 'Ocorreu um erro ao autenticar com o Login Social');
        }
    };

    // renders
    const RenderButtonGoogle = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.basic.orange}`} onClick={renderProps.onClick}>
            <IconOption src={iconGoogle} />
            <TextOption color={colors.basic.orange}>Criar com Google</TextOption>
        </ButtonOption>
    );

    const RenderButtonFacebook = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.theme.blue}`} onClick={renderProps.onClick}>
            <IconOption src={iconFacebook} />
            <TextOption color={colors.theme.blue}>Criar com Facebook</TextOption>
        </ButtonOption>
    );

    const RenderButtonInstagram = (renderProps: GoogleRenderProps) => (
        <ButtonOption border={`1px solid ${colors.basic.purple}`} onClick={renderProps.onClick}>
            <IconOption src={iconInstagram} />
            <TextOption color={colors.basic.purple}>Criar com Instagram</TextOption>
        </ButtonOption>
    );

    return (
        <Container>
            <Home>
                <ToNavigation pathRoute={pathRoute} redirect={redirect} />
                <ContainerGoBack src={arrowLeftPink} onClick={GoBack} />
                <Logo src={logoFullSize} />
                <InputAuth placeholder="Email" value={email} type="email" onChange={onChangeEmail} />
                <InputAuth placeholder="Senha" value={password} type="password" onChange={onChangePassword} />
                <ButtonAuth onClick={Signin} activeButton={email.length > 0 && password.length > 0} label="Entrar" />
                <TextActionNavigation>
                    Esqueceu sua senha?
                    <TextLinkingNavigation href="/forgotPassword" type="button">Toque aqui!</TextLinkingNavigation>
                </TextActionNavigation>
                <ContainerOrLine>
                    <Line />
                    <TextActionNavigation>OU</TextActionNavigation>
                    <Line />
                </ContainerOrLine>
                <GoogleLogin
                    clientId="318677929949-ogp84v6rhqldmmnm461269apago3q3gl.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                    render={RenderButtonGoogle}
                />
                <FacebookLogin
                    appId="499935777656640"
                    fields="name,email,picture"
                    callback={responseFacebook}
                    buttonStyle={stylesButtonFacebook}
                    render={RenderButtonFacebook}
                />
                <InstagramLogin
                    clientId="438798047161039"
                    onSuccess={responseInstagram}
                    onFailure={responseInstagram}
                    scope="user_profile,user_media"
                    render={RenderButtonInstagram}
                />
            </Home>
        </Container>
    );
};

export default LoginPage;
