// libraries
import React from 'react';

// js
import { ButtonAuthStyles, TextButtonAuthStyles } from './buttonStyles';

// interfaces
interface Props {
    onClick: () => Promise<void>,
    label: string,
    activeButton: boolean,
}

const ButtonAuthComponent: React.FC<Props> = ({ onClick, label, activeButton }: Props) => (
    <ButtonAuthStyles
        onClick={onClick}
        valueLength={activeButton}
        disabled={!activeButton}
    >
        <TextButtonAuthStyles valueLength={activeButton}>{label}</TextButtonAuthStyles>
    </ButtonAuthStyles>
);

export default ButtonAuthComponent;
