// libraries
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

// js
import GlobalStyle from './styles/global';
import Routes from './routes/index';
import { persistor, store } from './store/index';

const App: React.FC = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
            <GlobalStyle />
        </PersistGate>
    </Provider>
);

export default App;
