// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface BannerInterfaces {
    imgBanner?: string,
    visibleDrawer?: boolean,
}

interface TextDefaultInterfaces {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
}
interface ContainerDefaultInterfaces {
    alignSelf?: string,
    flexDirection?: string,
    height?: string,
    visibleDrawer?: boolean,
    paddingLeft?: string,
}
interface CardInterfaces {
    imgCard?: string,
}

// styles
export const Home = styled.div`
    background-color: ${colors.basic.black};
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
`;

export const BlurredDescription = styled.div`
    align-items: center;
    align-self: flex-end;
    display: flex;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: transparent;
    justify-content: center;
    height: 30px;
    width: 90%;
`;

export const ButtonPlay = styled.button`
    align-items: center;
    display: flex;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    border: none;
    background-color: transparent;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    width: 150px;
    height: 40px;
    margin-top: 10px;
    transition-duration: 400ms;
    @media (max-width: 1200px) {
        width: 130px;
        height: 30px;
    }
    @media (max-width: 800px) {
        width: 100px;
        height: 20px;
    }
`;

export const ImgPlay = styled.img`
    object-fit: contain;
    margin-left: 10px;
    height: 15px;
    width: 10px;
    position: relative;
    z-index: 2;
    @media (max-width: 1200px) {
        height: 13px;
        width: 8px;
    }
    @media (max-width: 800px) {
        height: 11px;
        width: 6px;
    }
`;

export const Container = styled.div`
    background-color: ${colors.basic.black};
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: auto;
`;

export const ContainerInner = styled.div`
    background-color: ${colors.basic.black};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const IconCard = styled.img`
    object-fit: contain;
    height: 10px;
    width: 10px;
`;

export const ContainerHome = styled.div`
    flex-direction: column;
    transition-duration: 400ms;
    width: 100%;
    overflow-y: auto;
`;

export const ContentHome = styled.div<BannerInterfaces>`
    transition-duration: 400ms;
    padding-left: ${(props) => (props.visibleDrawer ? '210px' : '60px')};
    padding-top: 20px;
    width: 100%;
    @media (max-width: 800px) {
        padding-left: ${(props) => (props.visibleDrawer ? '95px' : '0px')};
    }
`;

export const Banner = styled.div<BannerInterfaces>`
    height: 300px;
    width: 100%;
    transition-duration: 400ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${(props) => (`url(${props.imgBanner})`)};
`;

export const ContainerShadowBanner = styled.div<BannerInterfaces>`
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6));
    display: flex;
    height: 300px;
    padding-top: 10px;
    justify-content: space-between;
    padding-left: ${(props) => (props.visibleDrawer ? '210px' : '60px')};
    padding-right: 10px;
    padding-bottom: 20px;
    width: 100%;
    transition-duration: 400ms;
    @media (max-width: 800px) {
        padding-left: 0px;
    }
`;

export const LogoBanner = styled.img`
    object-fit: contain;
    height: 45px;
    width: 190px;
    margin-right: 10px;
    @media (max-width: 1200px) {
        height: 35px;
        width: 100px;
    }
    @media (max-width: 800px) {
        height: 25px;
        width: 60px;
    }
`;

export const ContainerDefault = styled.div<ContainerDefaultInterfaces>`
    align-self: ${(props) => (props.alignSelf || 'flex-start')};
    /* align-items: center; */
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
    @media (max-width: 800px) {
        transition-duration: 400ms;
        margin-left: ${(props) => (props.visibleDrawer ? '105px' : '5px')};
    }
`;

export const TitleCards = styled.text`
    color: ${colors.basic.white};
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
`;

export const Card = styled.div<CardInterfaces>`
    display: flex;
    /* align-items: flex-end; */
    justify-content: center;
    background-size: cover;
    background-image: ${(props) => (`url(${props.imgCard})`)};
    background-repeat: no-repeat;
    width: 148px;
    height: 126px;
    min-width: 148px;
    min-height: 126px;
    border-radius: 10px;
    padding: 5px;
    margin-right: 10px;
`;

export const DescriptionCard = styled.text`
    color: ${colors.basic.white};
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
`;

export const ContainerCards = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    margin-bottom: 20px;
    width: 100%;
`;

export const ContainerAdjustCards = styled.div`
    display: flex;
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 10px;
    margin-right: 5px;
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background: ${colors.basic.white};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${colors.theme.grayIntense};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: ${colors.theme.grayUltraIntense};
    }
`;

export const ContainerProfile = styled.div<ContainerDefaultInterfaces>`
    align-self: ${(props) => (props.alignSelf || 'flex-start')};
    align-items: center;
    display: flex;
    flex-direction: ${(props) => (props.flexDirection || 'row')};
`;

export const ImgProfile = styled.img`
    height: 32px;
    margin-right: 10px;
    width: 32px;
    @media (max-width: 1200px) {
        height: 24px;
        width: 24px;
    }
    @media (max-width: 800px) {
        height: 16px;
        width: 16px;
    }
`;

export const TextDefault = styled.text<TextDefaultInterfaces>`
    color: ${(props) => (props.color || colors.basic.white)};
    font-size: ${(props) => (props.fontSize || '14px')};
    font-weight: ${(props) => (props.fontWeight || 'normal')};
    position: relative;
    z-index: 2;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    @media (max-width: 800px) {
        font-size: 11px;
    }
`;

export const TextPlay = styled.text`
    color: ${colors.basic.white};
    font-size: 16px;
    font-weight: bold;
    @media (max-width: 1200px) {
        font-size: 12px;
    }
    @media (max-width: 800px) {
        font-size: 8px;
    }
`;

export const TitleBanner = styled.text`
    color: ${colors.basic.white};
    font-weight: bold;
    font-size: 30px;
    @media (max-width: 1200px) {
        font-size: 20px;
    }
    @media (max-width: 800px) {
        font-size: 12px;
    }
`;

export const CursorPointer = styled.button`
    align-items: center;
    border: none;
    background-color: transparent;
    display: flex;
    cursor: pointer;
`;
