// js
import user from './user';

export const actions = {
    user: user.actions,
};

export const reducers = {
    user: user.reducer,
};
