// js
import constants from '../utils/consts';
import { infoUserInterfaces } from '../interfaces';

// constants
const { baseUrl } = constants;
const headerApplicationJson = {
    'Content-Type': 'application/json',
};

// interfaces
interface GetTokenSocialInterfaces {
    status: number,
    result: { id: string, username?: string, email?: string},
    message: string,
}

interface RegisterTokenSocialInterfaces {
    status: number,
    result: { social: infoUserInterfaces | null, token: string, tokenRefresh: string },
    message: string,
}

interface RegisterUserInterfaces {
    status: number,
    result: { user: infoUserInterfaces | null, token: string, tokenRefresh: string },
    message: string,
}

interface RecoverPasswordInterfaces {
    status: number,
    message: string,
}

// api
async function GetTokenSocial(
    clientId: string,
    clientSecret: string,
    grantType: string,
    redirectUri: string,
    code: string,
): Promise<GetTokenSocialInterfaces> {
    try {
        const method = 'GET';
        const configs = {
            method,
        };
        const response = await fetch(`${baseUrl}/instagram/getInfoUser?clientId=${clientId}&clientSecret=${clientSecret}&grantType=${grantType}&redirectUri=${redirectUri}&code=${code}`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: { id: '', username: '' },
            status: 500,
        };
    }
}

async function Login(
    email: string,
    password: string,
): Promise<RegisterUserInterfaces> {
    try {
        const data = {
            email,
            password,
            typeDevice: 'web-client',
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/token`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: { user: null, token: '', tokenRefresh: '' },
            status: 500,
        };
    }
}

async function RecoverPassword(
    email: string,
): Promise<RecoverPasswordInterfaces> {
    try {
        const data = {
            email,
            typeDevice: 'web-client',
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/recoverPassword`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            status: 500,
        };
    }
}

async function Register(
    name: string,
    email: string,
    password: string,
): Promise<RegisterUserInterfaces> {
    try {
        const data = {
            name,
            email,
            password,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: { user: null, token: '', tokenRefresh: '' },
            status: 500,
        };
    }
}

async function LoginSocial(
    name: string,
    email: string,
    idSocial: string,
): Promise<RegisterTokenSocialInterfaces> {
    try {
        const data = {
            name,
            email,
            idSocial,
        };
        const method = 'POST';
        const body = JSON.stringify(data);
        const headers = headerApplicationJson;
        const configs = {
            method,
            body,
            headers,
        };
        const response = await fetch(`${baseUrl}/users/loginSocial`, configs);
        const json = await response.json();
        return json;
    } catch (error) {
        return {
            message: '',
            result: { social: null, token: '', tokenRefresh: '' },
            status: 500,
        };
    }
}

export default {
    Login,
    GetTokenSocial,
    RecoverPassword,
    Register,
    LoginSocial,
};
